<template>
	<div id="site-status">
		<p class="title">Site Status</p>
		<DxDataGrid
			id="tableDX"
			:ref="dataGridRefName"
			:data-source="status"
			key-expr="ID"
			:show-borders="false"
			@cell-prepared="onCellPrepared"
		>
			<DxColumn
				css-class="cell-id"
				width="53"
				alignment="right"
				data-field="ID"
				:customize-text="customDataText"
				caption=""
			/>
			<DxColumn css-class="cell-name" data-field="Name" caption="" />z
			<DxColumn
				css-class="cell-data"
				data-field="Total_PV"
				:allow-sorting="true"
				cell-template="data-kwh"
				width="117"
				header-cell-template="headerTemplatePV"
			/>
			<DxColumn
				css-class="cell-data"
				data-field="Total_BESS"
				width="130"
				:allow-sorting="true"
				cell-template="data-kwh"
				header-cell-template="headerTemplateBESS"
			/>
			<DxColumn
				css-class="cell-data"
				width="117"
				data-field="Total_Grid"
				:allow-sorting="true"
				cell-template="data-kwh"
				header-cell-template="headerTemplateGrid"
			/>
			<DxColumn
				width="180"
				css-class="cell-operation"
				data-field="Operation_Status"
				cell-template="operation"
			/>
			<template #operation="{ data }">
				<p :class="data.value">{{ data.value }}</p>
			</template>
			<template #data-kwh="{ data }">
				<p class="data-kwh">{{ data.value }} <span>kWh</span></p>
			</template>
			<template #headerTemplatePV="{ data }">
				<CellBtn
					@onClickCell="onChangeSortBy('Total PV')"
					:sortBy="sortBy"
					:cellData="data"
				/>
			</template>
			<template #headerTemplateBESS="{ data }">
				<CellBtn
					@onClickCell="onChangeSortBy('Total BESS')"
					:sortBy="sortBy"
					:cellData="data"
				/>
			</template>
			<template #headerTemplateGrid="{ data }">
				<CellBtn
					@onClickCell="onChangeSortBy('Total Grid')"
					:sortBy="sortBy"
					:cellData="data"
				/>
			</template>
		</DxDataGrid>
	</div>
</template>
<script>
import { DxColumn, DxDataGrid, DxSorting } from "devextreme-vue/data-grid";
import DxCheckBox from "devextreme-vue/check-box";
import { status } from "../DummyData/SiteStatus";
import CellBtn from "./CellBtn";

export default {
	components: {
		DxCheckBox,
		DxColumn,
		DxDataGrid,
		DxSorting,
		CellBtn,
	},
	data() {
		return {
			positionDisableSorting: false,
			dataGridRefName: "dataGrid",
			status,
			sortBy: "",
		};
	},
	methods: {
		onClickCell(data) {
			console.log(data);
		},
		onChangeSortBy(keyword) {
			this.sortBy = keyword;
		},
		onValueChanged() {
			const dataGrid = this.$refs[this.dataGridRefName].instance;

			// dataGrid.columnOption(5, "sortOrder", undefined);
		},
		onCellPrepared(e) {
			if (e.rowType == "detailAdaptive") {
				e.cellElement.classList.add("adaptiveRowStyle");
			}
		},
		customDataText(cellInfo) {
			return cellInfo.value + ".";
		},
		sortStringsConsideringCulture(value1, value2) {
			if (!value1 && value2) return -1;
			if (!value1 && !value2) return 0;
			if (value1 && !value2) return 1;
			// Determines whether two strings are equivalent in the current locale
			return value1.localeCompare(value2);
		},
	},
};
</script>
<style scoped>
.options {
	padding: 20px;
	margin-top: 20px;
	background-color: rgba(191, 191, 191, 0.15);
}

.caption {
	font-size: 18px;
	font-weight: 500;
}

.option {
	margin-top: 10px;
}

#site-status {
}

#site-status .title {
	text-align: left !important;
	text-transform: none !important;
}

#tableDX {
	display: inherit;
}
</style>
