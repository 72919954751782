<template>
	<div id="sum-energy">
		<p
			style="
				width: 198px;
				height: 14px;
				font-family: Helvetica;
				font-weight: normal;
				font-style: italic;
				font-size: 12px;
				text-align: left;
				color: #fcfcfc;
			"
		>
			{{ currentTime }}
		</p>
		<p
			style="
				width: 278px;
				height: 18px;
				font-family: Helvetica;
				font-weight: bold;
				font-size: 15px;
				text-align: left;
				color: #61bfeb;
			"
		>
			{{ totalEnergyData.title }}
		</p>
		<div class="row">
			<div
				class="sum-energy-detail col-md-4"
				v-for="energy in totalEnergyData.detail"
				:key="energy.label"
			>
				<span style="font-size: 36px; line-height: 36px; color: #fff">
					{{ energy.value }}</span
				>
				<span
					style="font-size: 14px; text-align: right; color: #c7c7c7"
					>{{ energy.unit_name }}</span
				>
				<br />
				<span style="font-size: 16px; text-align: left; color: #61bfeb">
					{{ energy.label }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";

export default {
	components: {},
	props: {
		totalEnergyData: Object,
	},
	data: () => ({
		data: [],
		currentTime: moment(Date()).format("ddd MMM DD, hh:mm A"),
	}),
	mounted() {
		this.loadData();
	},
	methods: {
		loadData() {
			console.log("load");
			window.setInterval(() => {
				this.currentTime = moment(Date()).format("ddd MMM DD, hh:mm A");
			}, 30000);
		},
	},
};
</script>
