<template>
	<div>
		<div id="header">
			<img class="topleft" src="../assets/images/lefttop.svg" />
			<img class="logo" src="../assets/images/header.svg" />
			<img class="topright" src="../assets/images/topright.svg" />
		</div>
		<div id="mainpage" class="row">
			<div style="margin-top: -35px" id="panel1" class="col-md-3">
				<TotalEnergy :totalEnergyData="currentData.energy_summary" />
				<DailyCharge
					:dailyChargeData="currentData.daily_charge"
					:deilyChargeDetail="currentData.daily_charge.detail"
				/>
				<StationStatus
					:totalPorts="currentData.totalPort"
					:sites="pageData"
					:currentSite="currentData.Name"
					:stationStatus="currentData.stationStatus"
				/>
			</div>
			<div id="panel2" class="col-md-6">
				<Map @changeSite="changeSite" />
				<SiteStatus />
			</div>
			<div style="margin-top: -35px" id="panel3" class="col-md-3">
				<MonthlyRevenue
					:monthlyData="currentData.monthly_revenue"
					:dataSource="currentData.monthly_revenue.detail"
				/>
				<ScheduleOperation
					:monthlyAnalysisData="currentData.monthlyAnalysis"
					:realTimeData="currentData.realtime"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import TotalEnergy from "../components/TotalEnergySum";
import DailyCharge from "../components/DailyCharge";
import StationStatus from "../components/StationStatus";
import Map from "../components/Map";
import SiteStatus from "../components/SiteStatus";
import MonthlyRevenue from "../components/MonthlyRevenue";
import ScheduleOperation from "../components/ScheduleOperation";
import { pageData } from "../DummyData/EntireData";

export default {
	name: "Login",
	components: {
		TotalEnergy,
		DailyCharge,
		StationStatus,
		Map,
		SiteStatus,
		MonthlyRevenue,
		ScheduleOperation,
	},
	data() {
		return {
			items: [],
			currentData: pageData[0],
			pageData: pageData,
		};
	},
	mounted() {
		console.log(this.currentData.realtime);
	},
	methods: {
		changeSite(name) {
			let current = pageData.find((ele) => ele.Name == name);
			this.currentData = current;
		},
	},
};
</script>

<style>
#header {
	width: 100%;
}

.topleft {
	width: 20%;
	margin-top: -50px;
}

.logo {
	width: 60%;
}

.topright {
	width: 20%;
	margin-top: -50px;
}

#mainpage {
	margin: 0 !important;
}
@media (max-width: 1920px) and (min-width: 1920px) {
	#panel1 {
		width: 490px;
		margin-left: 10px;
		margin-right: 10px;
	}

	#panel2 {
		width: 885px;
		margin-right: 10px;
	}

	#panel3 {
		width: 505px;
		margin-right: 10px;
	}
	#footer {
		display: none;
	}
	#footer img {
		width: 100%;
	}
	/* CSS that should be displayed if width is equal to or less than 800px goes here */
}
</style>
