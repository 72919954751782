import moment from "moment";

export const pageData = [
	{
		ID: 1,
		Name: "New York Ave5",
		totalPort: 161,
		Total_PV: "183",
		Total_BESS: "176",
		Total_Grid: "249",
		Operation_Status: "Normal",
		location: {
			left: 300,
			top: 127,
		},
		total_energy: 1235,
		total_time: 1534,
		monthly_revenue: {
			title: "MONTHLY REVENUE vs OPERATION COST",
			subtitle: "(Last 6 Months)",
			net_revenue: "$59,327",
			net_revenue_label: "Net Revenue",
			detail: [
				{
					index: "1",
					time: "Nov-22",
					off_peak: 1234,
					mid_peak: 2345,
					peak: 3456,
					operation_cost: -6789,
				},
				{
					index: "2",
					time: "Dec-22",
					off_peak: 2134,
					mid_peak: 1345,
					peak: 6434,
					operation_cost: -9032,
				},
				{
					index: "3",
					time: "Jan-22",
					off_peak: 2213,
					mid_peak: 3123,
					peak: 1345,
					operation_cost: -2356,
				},
				{
					index: "4",
					time: "Feb-22",
					off_peak: 1356,
					mid_peak: 2356,
					peak: 4442,
					operation_cost: -8032,
				},
				{
					index: "5",
					time: "Mar-22",
					off_peak: 3253,
					mid_peak: 2234,
					peak: 6323,
					operation_cost: -4432,
				},
				{
					index: "6",
					time: "Apr-22",
					off_peak: 1367,
					mid_peak: 2999,
					peak: 2345,
					operation_cost: -5023,
				},
			],
		},
		realtime: [
			{
				region: "Charging Site Control",
				val: 340,
			},
			{
				region: "Utility Control",
				val: 100,
			},
			{
				region: "No Applicable",
				val: 460,
			},
			{
				region: "Out of Service",
				val: 100,
			},
		],
		monthlyAnalysis: [
			{
				index: "1",
				time: "Nov-22",
				charging_site_control: 21,
				utility_control: 13,
				no_applicable: 46,
				out_of_service: 20,
			},
			{
				index: "2",
				time: "Dec-22",
				charging_site_control: 24,
				utility_control: 10,
				no_applicable: 56,
				out_of_service: 10,
			},
			{
				index: "3",
				time: "Jan-22",
				charging_site_control: 21,
				utility_control: 20,
				no_applicable: 42,
				out_of_service: 30,
			},
			{
				index: "4",
				time: "Feb-22",
				charging_site_control: 22,
				utility_control: 15,
				no_applicable: 30,
				out_of_service: 11,
			},
			{
				index: "5",
				time: "Mar-22",
				charging_site_control: 22,
				utility_control: 17,
				no_applicable: 45,
				out_of_service: 15,
			},
			{
				index: "6",
				time: "Apr-22",
				charging_site_control: 28,
				utility_control: 14,
				no_applicable: 52,
				out_of_service: 12,
			},
		],
		stationStatus: [
			{
				region: "Charging",
				val: 21,
			},
			{
				region: "Completed",
				val: 47,
			},
			{
				region: "Available",
				val: 9,
			},
			{
				region: "Error",
				val: 14,
			},
			{
				region: "Unavailable",
				val: 9,
			},
		],
		energy_summary: {
			time: "Tuesday Dec 24, 11:30 PM",
			title: "Today energy summary",
			detail: [
				{ label: "Total PV", value: "2183", unit_name: "kWh" },
				{ label: "BESS Ch", value: "2246", unit_name: "kWh" },
				{ label: "BESS Dch", value: "2383", unit_name: "kWh" },
				{ label: "EVSE", value: "2412", unit_name: "kWh" },
				{ label: "Others", value: "1534", unit_name: "kWh" },
				{ label: "NET", value: "2460", unit_name: "kWh" },
			],
		},
		daily_charge: {
			title: "DAILY CHARGED VEHICLES vs.CONSUMED ENERGY",
			detail: [
				{
					index: "1",
					time: moment().subtract(7, "days").format("MMM DD"),
					charge: 17,
					sum_energy: 130.2,
				},
				{
					index: "2",
					time: moment().subtract(6, "days").format("MMM DD"),
					charge: 15,
					sum_energy: 131.7,
				},
				{
					index: "3",
					time: moment().subtract(5, "days").format("MMM DD"),
					charge: 22,
					sum_energy: 140.1,
				},
				{
					index: "4",
					time: moment().subtract(4, "days").format("MMM DD"),
					charge: 10,
					sum_energy: 120.5,
				},
				{
					index: "5",
					time: moment().subtract(3, "days").format("MMM DD"),
					charge: 14,
					sum_energy: 145.4,
				},
				{
					index: "6",
					time: moment().subtract(2, "days").format("MMM DD"),
					charge: 14,
					sum_energy: 129.8,
				},
				{
					index: "7",
					time: moment().subtract(1, "days").format("MMM DD"),
					charge: 9,
					sum_energy: 125.5,
				},
			],
		},
	},
	{
		ID: 2,
		Name: "Los Angeles St8",
		Total_PV: "142",
		totalPort: 151,
		Total_BESS: "176",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		location: {
			left: 400,
			top: 300,
		},
		total_energy: 2321,
		total_time: 1666,
		monthly_revenue: {
			title: "MONTHLY REVENUE vs OPERATION COST",
			subtitle: "(Last 6 Months)",
			net_revenue: "$59,327",
			net_revenue_label: "Net Revenue",
			detail: [
				{
					index: "1",
					time: "Nov-22",
					off_peak: 1234,
					mid_peak: 2345,
					peak: 3456,
					operation_cost: -6789,
				},
				{
					index: "2",
					time: "Dec-22",
					off_peak: 2134,
					mid_peak: 1345,
					peak: 6434,
					operation_cost: -9032,
				},
				{
					index: "3",
					time: "Jan-22",
					off_peak: 2213,
					mid_peak: 3123,
					peak: 1345,
					operation_cost: -2356,
				},
				{
					index: "4",
					time: "Feb-22",
					off_peak: 1356,
					mid_peak: 2356,
					peak: 4442,
					operation_cost: -8032,
				},
				{
					index: "5",
					time: "Mar-22",
					off_peak: 3253,
					mid_peak: 2234,
					peak: 6323,
					operation_cost: -4432,
				},
				{
					index: "6",
					time: "Apr-22",
					off_peak: 1367,
					mid_peak: 2999,
					peak: 2345,
					operation_cost: -5023,
				},
			],
		},
		realtime: [
			{
				region: "Charging Site Control",
				val: 290,
			},
			{
				region: "Utility Control",
				val: 150,
			},
			{
				region: "No Applicable",
				val: 560,
			},
			{
				region: "Out of Service",
				val: 100,
			},
		],
		monthlyAnalysis: [
			{
				index: "1",
				time: "Nov-22",
				charging_site_control: 21,
				utility_control: 13,
				no_applicable: 46,
				out_of_service: 20,
			},
			{
				index: "2",
				time: "Dec-22",
				charging_site_control: 24,
				utility_control: 10,
				no_applicable: 56,
				out_of_service: 10,
			},
			{
				index: "3",
				time: "Jan-22",
				charging_site_control: 21,
				utility_control: 20,
				no_applicable: 62,
				out_of_service: 30,
			},
			{
				index: "4",
				time: "Feb-22",
				charging_site_control: 22,
				utility_control: 15,
				no_applicable: 40,
				out_of_service: 11,
			},
			{
				index: "5",
				time: "Mar-22",
				charging_site_control: 22,
				utility_control: 17,
				no_applicable: 35,
				out_of_service: 15,
			},
			{
				index: "6",
				time: "Apr-22",
				charging_site_control: 28,
				utility_control: 15,
				no_applicable: 50,
				out_of_service: 13,
			},
		],
		stationStatus: [
			{
				region: "Charging",
				val: 21,
			},
			{
				region: "Completed",
				val: 47,
			},
			{
				region: "Available",
				val: 9,
			},
			{
				region: "Error",
				val: 14,
			},
			{
				region: "Unavailable",
				val: 9,
			},
		],
		energy_summary: {
			time: "Tuesday Dec 24, 11:30 PM",
			title: "Today energy summary",
			detail: [
				{ label: "Total PV", value: "2383", unit_name: "kWh" },
				{ label: "BESS Ch", value: "2366", unit_name: "kWh" },
				{ label: "BESS Dch", value: "2263", unit_name: "kWh" },
				{ label: "EVSE", value: "2262", unit_name: "kWh" },
				{ label: "Others", value: "2234", unit_name: "kWh" },
				{ label: "NET", value: "2405", unit_name: "kWh" },
			],
		},
		daily_charge: {
			title: "DAILY CHARGED VEHICLES vs.CONSUMED ENERGY",
			detail: [
				{
					index: "1",
					time: moment().subtract(7, "days").format("MMM DD"),
					charge: 18,
					sum_energy: 130.2,
				},
				{
					index: "2",
					time: moment().subtract(6, "days").format("MMM DD"),
					charge: 11,
					sum_energy: 101.7,
				},
				{
					index: "3",
					time: moment().subtract(5, "days").format("MMM DD"),
					charge: 20,
					sum_energy: 140.1,
				},
				{
					index: "4",
					time: moment().subtract(4, "days").format("MMM DD"),
					charge: 15,
					sum_energy: 120.5,
				},
				{
					index: "5",
					time: moment().subtract(3, "days").format("MMM DD"),
					charge: 13,
					sum_energy: 105.4,
				},
				{
					index: "6",
					time: moment().subtract(2, "days").format("MMM DD"),
					charge: 14,
					sum_energy: 109.8,
				},
				{
					index: "7",
					time: moment().subtract(1, "days").format("MMM DD"),
					charge: 17,
					sum_energy: 125.5,
				},
			],
		},
	},
	{
		ID: 3,
		Name: "Tasmania Ave6",
		Total_PV: "230",
		totalPort: 200,
		Total_BESS: "176",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		location: {
			left: 600,
			top: 278,
		},
		total_energy: 1111,
		total_time: 2222,
		monthly_revenue: {
			title: "MONTHLY REVENUE vs OPERATION COST",
			subtitle: "(Last 6 Months)",
			net_revenue: "$59,327",
			net_revenue_label: "Net Revenue",
			detail: [
				{
					index: "1",
					time: "Nov-22",
					off_peak: 1234,
					mid_peak: 2345,
					peak: 3456,
					operation_cost: -6789,
				},
				{
					index: "2",
					time: "Dec-22",
					off_peak: 2134,
					mid_peak: 1345,
					peak: 6434,
					operation_cost: -9032,
				},
				{
					index: "3",
					time: "Jan-22",
					off_peak: 2213,
					mid_peak: 3123,
					peak: 1345,
					operation_cost: -2356,
				},
				{
					index: "4",
					time: "Feb-22",
					off_peak: 1356,
					mid_peak: 2356,
					peak: 4442,
					operation_cost: -8032,
				},
				{
					index: "5",
					time: "Mar-22",
					off_peak: 3253,
					mid_peak: 2234,
					peak: 6323,
					operation_cost: -4432,
				},
				{
					index: "6",
					time: "Apr-22",
					off_peak: 1367,
					mid_peak: 2999,
					peak: 2345,
					operation_cost: -5023,
				},
			],
		},
		realtime: [
			{
				region: "Charging Site Control",
				val: 240,
			},
			{
				region: "Utility Control",
				val: 100,
			},
			{
				region: "No Applicable",
				val: 510,
			},
			{
				region: "Out of Service",
				val: 150,
			},
		],
		monthlyAnalysis: [
			{
				index: "1",
				time: "Nov-22",
				charging_site_control: 20,
				utility_control: 14,
				no_applicable: 45,
				out_of_service: 21,
			},
			{
				index: "2",
				time: "Dec-22",
				charging_site_control: 24,
				utility_control: 10,
				no_applicable: 46,
				out_of_service: 20,
			},
			{
				index: "3",
				time: "Jan-22",
				charging_site_control: 21,
				utility_control: 20,
				no_applicable: 42,
				out_of_service: 40,
			},
			{
				index: "4",
				time: "Feb-22",
				charging_site_control: 22,
				utility_control: 25,
				no_applicable: 40,
				out_of_service: 21,
			},
			{
				index: "5",
				time: "Mar-22",
				charging_site_control: 22,
				utility_control: 17,
				no_applicable: 25,
				out_of_service: 25,
			},
			{
				index: "6",
				time: "Apr-22",
				charging_site_control: 28,
				utility_control: 15,
				no_applicable: 40,
				out_of_service: 23,
			},
		],
		stationStatus: [
			{
				region: "Charging",
				val: 21,
			},
			{
				region: "Completed",
				val: 47,
			},
			{
				region: "Available",
				val: 9,
			},
			{
				region: "Error",
				val: 14,
			},
			{
				region: "Unavailable",
				val: 9,
			},
		],
		energy_summary: {
			time: "Tuesday Dec 24, 11:30 PM",
			title: "Today energy summary",
			detail: [
				{ label: "Total PV", value: "2283", unit_name: "kWh" },
				{ label: "BESS Ch", value: "2246", unit_name: "kWh" },
				{ label: "BESS Dch", value: "2183", unit_name: "kWh" },
				{ label: "EVSE", value: "2242", unit_name: "kWh" },
				{ label: "Others", value: "1244", unit_name: "kWh" },
				{ label: "NET", value: "2420", unit_name: "kWh" },
			],
		},
		daily_charge: {
			title: "DAILY CHARGED VEHICLES vs.CONSUMED ENERGY",
			detail: [
				{
					index: "1",
					time: moment().subtract(7, "days").format("MMM DD"),
					charge: 18,
					sum_energy: 130.2,
				},
				{
					index: "2",
					time: moment().subtract(6, "days").format("MMM DD"),
					charge: 11,
					sum_energy: 101.7,
				},
				{
					index: "3",
					time: moment().subtract(5, "days").format("MMM DD"),
					charge: 20,
					sum_energy: 140.1,
				},
				{
					index: "4",
					time: moment().subtract(4, "days").format("MMM DD"),
					charge: 15,
					sum_energy: 120.5,
				},
				{
					index: "5",
					time: moment().subtract(3, "days").format("MMM DD"),
					charge: 13,
					sum_energy: 105.4,
				},
				{
					index: "6",
					time: moment().subtract(2, "days").format("MMM DD"),
					charge: 14,
					sum_energy: 109.8,
				},
				{
					index: "7",
					time: moment().subtract(1, "days").format("MMM DD"),
					charge: 17,
					sum_energy: 125.5,
				},
			],
		},
	},
	{
		ID: 4,
		Name: "Chicago Time Square",
		Total_PV: "219",
		Total_BESS: "176",
		Total_Grid: "249",
		totalPort: 183,
		Operation_Status: "Alarm",
		location: {
			left: 550,
			top: 250,
		},
		total_energy: 1356,
		total_time: 1345,
		monthly_revenue: {
			title: "MONTHLY REVENUE vs OPERATION COST",
			subtitle: "(Last 6 Months)",
			net_revenue: "$59,327",
			net_revenue_label: "Net Revenue",
			detail: [
				{
					index: "1",
					time: "Nov-22",
					off_peak: 1234,
					mid_peak: 2345,
					peak: 3456,
					operation_cost: -6789,
				},
				{
					index: "2",
					time: "Dec-22",
					off_peak: 2134,
					mid_peak: 1345,
					peak: 6434,
					operation_cost: -9032,
				},
				{
					index: "3",
					time: "Jan-22",
					off_peak: 2213,
					mid_peak: 3123,
					peak: 1345,
					operation_cost: -2356,
				},
				{
					index: "4",
					time: "Feb-22",
					off_peak: 1356,
					mid_peak: 2356,
					peak: 4442,
					operation_cost: -8032,
				},
				{
					index: "5",
					time: "Mar-22",
					off_peak: 3253,
					mid_peak: 2234,
					peak: 6323,
					operation_cost: -4432,
				},
				{
					index: "6",
					time: "Apr-22",
					off_peak: 1367,
					mid_peak: 2999,
					peak: 2345,
					operation_cost: -5023,
				},
			],
		},
		realtime: [
			{
				region: "Charging Site Control",
				val: 190,
			},
			{
				region: "Utility Control",
				val: 150,
			},
			{
				region: "No Applicable",
				val: 540,
			},
			{
				region: "Out of Service",
				val: 120,
			},
		],
		monthlyAnalysis: [
			{
				index: "1",
				time: "Nov-22",
				charging_site_control: 21,
				utility_control: 13,
				no_applicable: 46,
				out_of_service: 20,
			},
			{
				index: "2",
				time: "Dec-22",
				charging_site_control: 24,
				utility_control: 10,
				no_applicable: 56,
				out_of_service: 10,
			},
			{
				index: "3",
				time: "Jan-22",
				charging_site_control: 21,
				utility_control: 20,
				no_applicable: 62,
				out_of_service: 30,
			},
			{
				index: "4",
				time: "Feb-22",
				charging_site_control: 22,
				utility_control: 15,
				no_applicable: 40,
				out_of_service: 11,
			},
			{
				index: "5",
				time: "Mar-22",
				charging_site_control: 22,
				utility_control: 17,
				no_applicable: 35,
				out_of_service: 15,
			},
			{
				index: "6",
				time: "Apr-22",
				charging_site_control: 28,
				utility_control: 15,
				no_applicable: 50,
				out_of_service: 13,
			},
		],
		stationStatus: [
			{
				region: "Charging",
				val: 20,
			},
			{
				region: "Completed",
				val: 48,
			},
			{
				region: "Available",
				val: 10,
			},
			{
				region: "Error",
				val: 13,
			},
			{
				region: "Unavailable",
				val: 9,
			},
		],
		energy_summary: {
			time: "Tuesday Dec 24, 11:30 PM",
			title: "Today energy summary",
			detail: [
				{ label: "Total PV", value: "2383", unit_name: "kWh" },
				{ label: "BESS Ch", value: "2346", unit_name: "kWh" },
				{ label: "BESS Dch", value: "2283", unit_name: "kWh" },
				{ label: "EVSE", value: "2212", unit_name: "kWh" },
				{ label: "Others", value: "1234", unit_name: "kWh" },
				{ label: "NET", value: "2400", unit_name: "kWh" },
			],
		},
		daily_charge: {
			title: "DAILY CHARGED VEHICLES vs.CONSUMED ENERGY",
			detail: [
				{
					index: "1",
					time: moment().subtract(7, "days").format("MMM DD"),
					charge: 18,
					sum_energy: 130.2,
				},
				{
					index: "2",
					time: moment().subtract(6, "days").format("MMM DD"),
					charge: 11,
					sum_energy: 101.7,
				},
				{
					index: "3",
					time: moment().subtract(5, "days").format("MMM DD"),
					charge: 20,
					sum_energy: 140.1,
				},
				{
					index: "4",
					time: moment().subtract(4, "days").format("MMM DD"),
					charge: 15,
					sum_energy: 120.5,
				},
				{
					index: "5",
					time: moment().subtract(3, "days").format("MMM DD"),
					charge: 13,
					sum_energy: 105.4,
				},
				{
					index: "6",
					time: moment().subtract(2, "days").format("MMM DD"),
					charge: 14,
					sum_energy: 109.8,
				},
				{
					index: "7",
					time: moment().subtract(1, "days").format("MMM DD"),
					charge: 17,
					sum_energy: 125.5,
				},
			],
		},
	},
	{
		ID: 5,
		Name: "New York Time Square",
		Total_PV: "190",
		Total_BESS: "176",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		totalPort: 211,
		location: {
			left: 750,
			top: 150,
		},
		total_energy: 1222,
		total_time: 1333,
		monthly_revenue: {
			title: "MONTHLY REVENUE vs OPERATION COST",
			subtitle: "(Last 6 Months)",
			net_revenue: "$59,327",
			net_revenue_label: "Net Revenue",
			detail: [
				{
					index: "1",
					time: "Nov-22",
					off_peak: 4222,
					mid_peak: 3333,
					peak: 3456,
					operation_cost: -6789,
				},
				{
					index: "2",
					time: "Dec-22",
					off_peak: 2134,
					mid_peak: 2222,
					peak: 4444,
					operation_cost: -8032,
				},
				{
					index: "3",
					time: "Jan-22",
					off_peak: 2222,
					mid_peak: 5555,
					peak: 1345,
					operation_cost: -2356,
				},
				{
					index: "4",
					time: "Feb-22",
					off_peak: 1356,
					mid_peak: 2356,
					peak: 4442,
					operation_cost: -8032,
				},
				{
					index: "5",
					time: "Mar-22",
					off_peak: 3253,
					mid_peak: 2234,
					peak: 6323,
					operation_cost: -4432,
				},
				{
					index: "6",
					time: "Apr-22",
					off_peak: 1367,
					mid_peak: 2999,
					peak: 2345,
					operation_cost: -5023,
				},
			],
		},
		realtime: [
			{
				region: "Charging Site Control",
				val: 340,
			},
			{
				region: "Utility Control",
				val: 150,
			},
			{
				region: "No Applicable",
				val: 360,
			},
			{
				region: "Out of Service",
				val: 150,
			},
		],
		monthlyAnalysis: [
			{
				index: "1",
				time: "Nov-22",
				charging_site_control: 21,
				utility_control: 13,
				no_applicable: 46,
				out_of_service: 20,
			},
			{
				index: "2",
				time: "Dec-22",
				charging_site_control: 24,
				utility_control: 10,
				no_applicable: 56,
				out_of_service: 10,
			},
			{
				index: "3",
				time: "Jan-22",
				charging_site_control: 21,
				utility_control: 20,
				no_applicable: 62,
				out_of_service: 30,
			},
			{
				index: "4",
				time: "Feb-22",
				charging_site_control: 22,
				utility_control: 15,
				no_applicable: 40,
				out_of_service: 11,
			},
			{
				index: "5",
				time: "Mar-22",
				charging_site_control: 22,
				utility_control: 17,
				no_applicable: 35,
				out_of_service: 15,
			},
			{
				index: "6",
				time: "Apr-22",
				charging_site_control: 28,
				utility_control: 15,
				no_applicable: 50,
				out_of_service: 13,
			},
		],
		stationStatus: [
			{
				region: "Charging",
				val: 21,
			},
			{
				region: "Completed",
				val: 47,
			},
			{
				region: "Available",
				val: 9,
			},
			{
				region: "Error",
				val: 14,
			},
			{
				region: "Unavailable",
				val: 9,
			},
		],
		energy_summary: {
			time: "Tuesday Dec 24, 11:30 PM",
			title: "Today energy summary",
			detail: [
				{ label: "Total PV", value: "2383", unit_name: "kWh" },
				{ label: "BESS Ch", value: "2346", unit_name: "kWh" },
				{ label: "BESS Dch", value: "2283", unit_name: "kWh" },
				{ label: "EVSE", value: "2212", unit_name: "kWh" },
				{ label: "Others", value: "1234", unit_name: "kWh" },
				{ label: "NET", value: "2400", unit_name: "kWh" },
			],
		},
		daily_charge: {
			title: "DAILY CHARGED VEHICLES vs.CONSUMED ENERGY",
			detail: [
				{
					index: "1",
					time: moment().subtract(7, "days").format("MMM DD"),
					charge: 18,
					sum_energy: 130.2,
				},
				{
					index: "2",
					time: moment().subtract(6, "days").format("MMM DD"),
					charge: 11,
					sum_energy: 101.7,
				},
				{
					index: "3",
					time: moment().subtract(5, "days").format("MMM DD"),
					charge: 20,
					sum_energy: 140.1,
				},
				{
					index: "4",
					time: moment().subtract(4, "days").format("MMM DD"),
					charge: 15,
					sum_energy: 120.5,
				},
				{
					index: "5",
					time: moment().subtract(3, "days").format("MMM DD"),
					charge: 13,
					sum_energy: 105.4,
				},
				{
					index: "6",
					time: moment().subtract(2, "days").format("MMM DD"),
					charge: 14,
					sum_energy: 109.8,
				},
				{
					index: "7",
					time: moment().subtract(1, "days").format("MMM DD"),
					charge: 17,
					sum_energy: 125.5,
				},
			],
		},
	},
	{
		ID: 6,
		Name: "Texas Galveston Seashore",
		Total_PV: "127",
		Total_BESS: "176",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		totalPort: 121,
		location: {
			left: 320,
			top: 270,
		},
		total_energy: 1022,
		total_time: 1355,
		monthly_revenue: {
			title: "MONTHLY REVENUE vs OPERATION COST",
			subtitle: "(Last 6 Months)",
			net_revenue: "$59,327",
			net_revenue_label: "Net Revenue",
			detail: [
				{
					index: "1",
					time: "Nov-22",
					off_peak: 1234,
					mid_peak: 2245,
					peak: 1356,
					operation_cost: -5789,
				},
				{
					index: "2",
					time: "Dec-22",
					off_peak: 2234,
					mid_peak: 1445,
					peak: 5434,
					operation_cost: -9032,
				},
				{
					index: "3",
					time: "Jan-22",
					off_peak: 2213,
					mid_peak: 2123,
					peak: 2345,
					operation_cost: -4356,
				},
				{
					index: "4",
					time: "Feb-22",
					off_peak: 1356,
					mid_peak: 2356,
					peak: 3442,
					operation_cost: -6032,
				},
				{
					index: "5",
					time: "Mar-22",
					off_peak: 3253,
					mid_peak: 2234,
					peak: 5323,
					operation_cost: -5432,
				},
				{
					index: "6",
					time: "Apr-22",
					off_peak: 1367,
					mid_peak: 2999,
					peak: 2345,
					operation_cost: -5023,
				},
			],
		},
		realtime: [
			{
				region: "Charging Site Control",
				val: 540,
			},
			{
				region: "Utility Control",
				val: 100,
			},
			{
				region: "No Applicable",
				val: 360,
			},
			{
				region: "Out of Service",
				val: 100,
			},
		],
		monthlyAnalysis: [
			{
				index: "1",
				time: "Nov-22",
				charging_site_control: 21,
				utility_control: 13,
				no_applicable: 46,
				out_of_service: 20,
			},
			{
				index: "2",
				time: "Dec-22",
				charging_site_control: 24,
				utility_control: 10,
				no_applicable: 56,
				out_of_service: 10,
			},
			{
				index: "3",
				time: "Jan-22",
				charging_site_control: 21,
				utility_control: 20,
				no_applicable: 62,
				out_of_service: 30,
			},
			{
				index: "4",
				time: "Feb-22",
				charging_site_control: 22,
				utility_control: 15,
				no_applicable: 40,
				out_of_service: 11,
			},
			{
				index: "5",
				time: "Mar-22",
				charging_site_control: 22,
				utility_control: 17,
				no_applicable: 35,
				out_of_service: 15,
			},
			{
				index: "6",
				time: "Apr-22",
				charging_site_control: 28,
				utility_control: 15,
				no_applicable: 50,
				out_of_service: 13,
			},
		],
		stationStatus: [
			{
				region: "Charging",
				val: 33,
			},
			{
				region: "Completed",
				val: 35,
			},
			{
				region: "Available",
				val: 11,
			},
			{
				region: "Error",
				val: 11,
			},
			{
				region: "Unavailable",
				val: 10,
			},
		],
		energy_summary: {
			time: "Tuesday Dec 24, 11:30 PM",
			title: "Today energy summary",
			detail: [
				{ label: "Total PV", value: "2283", unit_name: "kWh" },
				{ label: "BESS Ch", value: "2336", unit_name: "kWh" },
				{ label: "BESS Dch", value: "2183", unit_name: "kWh" },
				{ label: "EVSE", value: "2215", unit_name: "kWh" },
				{ label: "Others", value: "1434", unit_name: "kWh" },
				{ label: "NET", value: "1440", unit_name: "kWh" },
			],
		},
		daily_charge: {
			title: "DAILY CHARGED VEHICLES vs.CONSUMED ENERGY",
			detail: [
				{
					index: "1",
					time: moment().subtract(7, "days").format("MMM DD"),
					charge: 20,
					sum_energy: 100.2,
				},
				{
					index: "2",
					time: moment().subtract(6, "days").format("MMM DD"),
					charge: 17,
					sum_energy: 91.7,
				},
				{
					index: "3",
					time: moment().subtract(5, "days").format("MMM DD"),
					charge: 20,
					sum_energy: 130.1,
				},
				{
					index: "4",
					time: moment().subtract(4, "days").format("MMM DD"),
					charge: 25,
					sum_energy: 120.5,
				},
				{
					index: "5",
					time: moment().subtract(3, "days").format("MMM DD"),
					charge: 23,
					sum_energy: 123.4,
				},
				{
					index: "6",
					time: moment().subtract(2, "days").format("MMM DD"),
					charge: 19,
					sum_energy: 111.8,
				},
				{
					index: "7",
					time: moment().subtract(1, "days").format("MMM DD"),
					charge: 18,
					sum_energy: 112.5,
				},
			],
		},
	},
	{
		ID: 7,
		Name: "LA Airport Road",
		Total_PV: "166",
		Total_BESS: "176",
		Total_Grid: "249",
		totalPort: 261,
		Operation_Status: "Normal",
		location: {
			left: 500,
			top: 100,
		},
		total_energy: 1444,
		total_time: 1355,
		monthly_revenue: {
			title: "MONTHLY REVENUE vs OPERATION COST",
			subtitle: "(Last 6 Months)",
			net_revenue: "$59,327",
			net_revenue_label: "Net Revenue",
			detail: [
				{
					index: "1",
					time: "Nov-22",
					off_peak: 1264,
					mid_peak: 3345,
					peak: 2456,
					operation_cost: -4489,
				},
				{
					index: "2",
					time: "Dec-22",
					off_peak: 2134,
					mid_peak: 2345,
					peak: 4434,
					operation_cost: -2332,
				},
				{
					index: "3",
					time: "Jan-22",
					off_peak: 2213,
					mid_peak: 5123,
					peak: 1345,
					operation_cost: -4356,
				},
				{
					index: "4",
					time: "Feb-22",
					off_peak: 2356,
					mid_peak: 2356,
					peak: 4442,
					operation_cost: -8032,
				},
				{
					index: "5",
					time: "Mar-22",
					off_peak: 3253,
					mid_peak: 2234,
					peak: 6323,
					operation_cost: -4432,
				},
				{
					index: "6",
					time: "Apr-22",
					off_peak: 2367,
					mid_peak: 2999,
					peak: 4345,
					operation_cost: -5023,
				},
			],
		},
		realtime: [
			{
				region: "Charging Site Control",
				val: 240,
			},
			{
				region: "Utility Control",
				val: 100,
			},
			{
				region: "No Applicable",
				val: 490,
			},
			{
				region: "Out of Service",
				val: 170,
			},
		],
		monthlyAnalysis: [
			{
				index: "1",
				time: "Nov-22",
				charging_site_control: 21,
				utility_control: 13,
				no_applicable: 46,
				out_of_service: 20,
			},
			{
				index: "2",
				time: "Dec-22",
				charging_site_control: 24,
				utility_control: 10,
				no_applicable: 56,
				out_of_service: 10,
			},
			{
				index: "3",
				time: "Jan-22",
				charging_site_control: 21,
				utility_control: 20,
				no_applicable: 62,
				out_of_service: 30,
			},
			{
				index: "4",
				time: "Feb-22",
				charging_site_control: 22,
				utility_control: 15,
				no_applicable: 40,
				out_of_service: 11,
			},
			{
				index: "5",
				time: "Mar-22",
				charging_site_control: 22,
				utility_control: 17,
				no_applicable: 35,
				out_of_service: 15,
			},
			{
				index: "6",
				time: "Apr-22",
				charging_site_control: 28,
				utility_control: 15,
				no_applicable: 50,
				out_of_service: 13,
			},
		],
		stationStatus: [
			{
				region: "Charging",
				val: 21,
			},
			{
				region: "Completed",
				val: 37,
			},
			{
				region: "Available",
				val: 22,
			},
			{
				region: "Error",
				val: 14,
			},
			{
				region: "Unavailable",
				val: 13,
			},
		],
		energy_summary: {
			time: "Tuesday Dec 24, 11:30 PM",
			title: "Today energy summary",
			detail: [
				{ label: "Total PV", value: "2383", unit_name: "kWh" },
				{ label: "BESS Ch", value: "2346", unit_name: "kWh" },
				{ label: "BESS Dch", value: "2283", unit_name: "kWh" },
				{ label: "EVSE", value: "2212", unit_name: "kWh" },
				{ label: "Others", value: "1234", unit_name: "kWh" },
				{ label: "NET", value: "2400", unit_name: "kWh" },
			],
		},
		daily_charge: {
			title: "DAILY CHARGED VEHICLES vs.CONSUMED ENERGY",
			detail: [
				{
					index: "1",
					time: moment().subtract(7, "days").format("MMM DD"),
					charge: 20,
					sum_energy: 120.2,
				},
				{
					index: "2",
					time: moment().subtract(6, "days").format("MMM DD"),
					charge: 21,
					sum_energy: 111.7,
				},
				{
					index: "3",
					time: moment().subtract(5, "days").format("MMM DD"),
					charge: 10,
					sum_energy: 90.1,
				},
				{
					index: "4",
					time: moment().subtract(4, "days").format("MMM DD"),
					charge: 15,
					sum_energy: 110.5,
				},
				{
					index: "5",
					time: moment().subtract(3, "days").format("MMM DD"),
					charge: 15,
					sum_energy: 115.4,
				},
				{
					index: "6",
					time: moment().subtract(2, "days").format("MMM DD"),
					charge: 24,
					sum_energy: 129.8,
				},
				{
					index: "7",
					time: moment().subtract(1, "days").format("MMM DD"),
					charge: 7,
					sum_energy: 80.5,
				},
			],
		},
	},
];
