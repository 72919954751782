<template>
	<div id="action-btn" @click="show">
		{{ cellData.column.caption }}
		<span v-if="sortBy === cellData.column.caption">
			<img height="18" src="../assets/images/sorting.svg" />
		</span>
		<span v-if="sortBy !== cellData.column.caption">
			<img height="18" src="../assets/images/no_sort.svg" />
		</span>
	</div>
</template>

<script>
export default {
	components: {},
	props: {
		cellData: Object,
		sortBy: String,
	},
	data: () => ({
		data: [],
	}),
	mounted() {
		this.loadData();
	},
	watch: {
		cellData: function (newVal, oldVal) {
			console.log("old", oldVal);
			console.log("new", newVal);
		},
	},
	methods: {
		show() {
			this.$emit("onClickCell");
		},
		loadData() {
			console.log(this.cellData);
		},
	},
};
</script>
