export const status = [
	{
		ID: 1,
		Name: "New York Ave5",
		Total_PV: "183",
		Total_BESS: "145",
		Total_Grid: "249",
		Operation_Status: "Normal",
		location: {
			left: "50%",
			top: "25%",
		},
	},
	{
		ID: 2,
		Name: "Los Angeles St8",
		Total_PV: "142",
		Total_BESS: "176",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		location: {
			left: "44%",
			top: "33%",
		},
	},
	{
		ID: 3,
		Name: "Tasmania Ave6",
		Total_PV: "230",
		Total_BESS: "222",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		location: {
			left: "60%",
			top: "27%",
		},
	},
	{
		ID: 4,
		Name: "Chicago Time Square",
		Total_PV: "219",
		Total_BESS: "176",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		location: {
			left: "45%",
			top: "55%",
		},
	},
	{
		ID: 5,
		Name: "New York Time Square",
		Total_PV: "190",
		Total_BESS: "156",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		location: {
			left: "75%",
			top: "65%",
		},
	},
	{
		ID: 6,
		Name: "Texas Galveston Seashore",
		Total_PV: "127",
		Total_BESS: "190",
		Total_Grid: "249",
		Operation_Status: "Alarm",
		location: {
			left: "32%",
			top: "27%",
		},
	},
	{
		ID: 7,
		Name: "LA Airport Road",
		Total_PV: "166",
		Total_BESS: "210",
		Total_Grid: "249",
		Operation_Status: "Normal",
		location: {
			left: "50%",
			top: "70%",
		},
	},
];
