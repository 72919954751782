<template>
	<div id="stationstatus">
		<div id="picksite">
			<div>
				<select
					v-model="currentSite"
					@change="changeSite(currentSite)"
					id="siteselect"
				>
					<option value="all" default>All sites</option>
					<option
						:value="site.Name"
						v-for="site in sites"
						:key="site.Name"
					>
						{{ site.Name }}
					</option>
				</select>
			</div>
			<div id="site_energy">
				<img src="../assets/images/lighting.svg " />
				<div>{{ totalEnergy }} online</div>
			</div>
			<div id="site_time">
				<img src="../assets/images/clock.svg " />
				<div>{{ totalTime }} hours</div>
			</div>
		</div>
		<div class="title">Station Status</div>
		<DxPieChart
			id="piestation"
			:data-source="stationStatus"
			type="doughnut"
			:customize-point="customizePoint"
			:innerRadius="0.8"
			center-template="centerTemplate"
		>
			<DxSeries argument-field="region">
				<DxLabel
					:customize-text="customizeText"
					background-color="transparent"
					:visible="true"
				>
					<DxConnector :visible="false" />
				</DxLabel>
			</DxSeries>
			<DxLegend
				:visible="false"
				:margin="0"
				horizontal-alignment="center"
				:row-count="2"
				vertical-alignment="bottom"
				:paddingTopBottom="200"
			/>
			<template #centerTemplate>
				<svg>
					<text
						:style="{ fontSize: 14, fill: '#67e2ff' }"
						text-anchor="middle"
						x="100"
						y="120"
					>
						<tspan x="100">TOTAL</tspan>
						<tspan x="100" dy="15px">PORTS</tspan>
						<tspan
							:style="{ fontSize: 18, fill: '#ffffff' }"
							x="100"
							dy="25px"
						>
							{{ totalPorts }}
						</tspan>
					</text>
				</svg>
			</template>
		</DxPieChart>
		<div id="chartLegend">
			<div>
				<div class="dot" style="background: #14e4ff"></div>
				<p>Charging</p>
			</div>
			<div>
				<div class="dot" style="background: #ff9a45"></div>
				<p>Completed</p>
			</div>
			<div>
				<div class="dot" style="background: #5fffaf"></div>
				<p>Available</p>
			</div>
			<div>
				<div class="dot" style="background: #ed0252"></div>
				<p>Error</p>
			</div>
			<div>
				<div class="dot" style="background: #beeaff"></div>
				<p>Unavailable</p>
			</div>
		</div>
	</div>
</template>
<script>
import DxPieChart, {
	DxLegend,
	DxSeries,
	DxTooltip,
	DxFormat,
	DxLabel,
	DxConnector,
	DxExport,
} from "devextreme-vue/pie-chart";

export default {
	components: {
		DxPieChart,
		DxLegend,
		DxSeries,
		DxTooltip,
		DxFormat,
		DxLabel,
		DxConnector,
		DxExport,
	},
	mounted() {
		this.changeSite("all");
	},
	props: {
		totalPorts: Number,
		sites: Array,
		stationStatus: Array,
	},
	data: () => ({
		totalEnergy: 0,
		totalTime: 0,
		currentSite: "all",
		palette: ["14E4FF", "#FF9A45", "#5FFFAF", "#ED0252", "#BEEAFF"],
	}),
	watch: {
		totalPorts: function (newVal, oldVal) {
			// watch it
			this.changeSite(this.currentSite);
		},
	},
	methods: {
		changeSite(site) {
			let sites = this.sites;
			if (site == "all") {
				let totalEnergy = 0;
				let totalTime = 0;
				for (var i = 0; i < sites.length; i++) {
					totalEnergy = totalEnergy + sites[i].total_energy;
					totalTime = totalTime + sites[i].total_time;
				}

				this.totalEnergy = totalEnergy;
				this.totalTime = totalTime;
				console.log("time", this.totalTime);
			} else {
				let newSite = this.sites.find((ele) => ele.Name == site);
				this.totalEnergy = newSite.total_energy;
				this.totalTime = newSite.total_time;
				console.log("time", this.totalTime);
			}
		},
		customizeText(pointInfo) {
			return `${pointInfo.percentText}`;
		},
		customizePoint(pointInfo) {
			let color = "";
			switch (pointInfo.argument) {
				case "Charging":
					color = "#14E4FF";
					break;
				case "Completed":
					color = "#FF9A45";
					break;
				case "Available":
					color = "#5FFFAF";
					break;
				case "Error":
					color = "#ED0252";
					break;
				case "Unavailable":
					color = "#BEEAFF";
					break;

				default:
				// code block
			}
			return color != "" ? { color: color } : {};
		},
	},
};
</script>
<style>
#stationstatus {
	position: relative;
	background: url("../assets/images/station_status_bg.svg") no-repeat;
	background-size: cover;
	background-position: center;
	padding-top: 20px;
	margin-top: 10px;
	height: 315px;
}
#piestation {
	width: 60%;
	margin-top: -20px;
	height: 80%;
}

#site_energy {
	display: flex;
}

#site_time {
	display: flex;
}

#site_energy img {
	margin-right: 10px;
	width: 25px;
}
#site_time img {
	margin-right: 10px;
	width: 23px;
}

#site_energy > div {
	width: 157px;
	height: 33px;
	background: rgba(92, 192, 235, 0.9);
	border-radius: 2px;
	text-align: left;
	border: 1px solid #14e4ff;
	color: #fff;
	padding-top: 2px;
	padding-left: 15px;
}

#site_time > div {
	width: 147px;
	height: 33px;
	color: #fff;
	text-align: left;
	padding-top: 2px;
	padding-left: 15px;
	border-radius: 2px;
	background: rgba(255, 152, 64, 0.83);
	border: 1px solid #ebb161;
	margin-top: 5px;
}

#picksite > div {
	text-align: center;
}

#picksite {
	width: 32%;
	position: absolute;
	right: 20px;
	top: 55px;
}

#picksite select {
	height: 24.13px;
	background: linear-gradient(#134164 0%, rgba(97, 191, 235, 0.44) 100%);
	width: 100%;
	font-family: "Helvetica Neue";
	font-weight: bold;
	font-size: 14px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #61bfeb;
	margin: 25px auto;
}

#chartLegend {
	display: flex;
	flex-wrap: wrap;
	padding-left: 30px;
	margin-top: -20px;
}

#chartLegend div {
	width: 30%;
	display: flex;
	height: 25px;
	text-align: center;
	line-height: 25px;
	vertical-align: "middle";
}

#chartLegend p {
	font-size: 16px;
	color: #fff !important;
	text-align: center;
}

#chartLegend .dot {
	margin-top: 5px;
	margin-right: 10px;
	width: 15px;
	height: 15px;
	border-radius: 10px;
}
</style>
