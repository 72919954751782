<template>
	<div id="monthlyrevenue">
		<div class="title">
			{{ monthlyData.title }}
		</div>
		<div class="subtitle">
			{{ monthlyData.subtitle }}
		</div>
		<div class="total_revenue">{{ changeFormat(this.total) }}</div>
		<div class="total_desc">Net Revenue</div>

		<DxChart
			id="monthlychart"
			:data-source="dataSource"
			:rotated="false"
			:bar-group-width="34"
		>
			<DxCommonSeriesSettings argument-field="time" type="stackedbar" />
			<DxCommonAxisSettings :visible="false">
				<DxGrid :visible="false" />
			</DxCommonAxisSettings>
			<DxSeries value-field="peak" name="Peak" color="#FF9F58">
				<DxLabel
					:visible="false"
					color="#FFF"
					:customize-text="customizeRevenue"
					position="outside"
				/>
			</DxSeries>
			<DxSeries value-field="mid_peak" name="Mid Peak" color="#6C42B2">
				<DxLabel
					:visible="false"
					color="#FFF"
					:customize-text="customizeRevenue"
					position="outside"
				/>
			</DxSeries>
			<DxSeries value-field="off_peak" name="Off Peak" color="#227117">
				<DxLabel
					:visible="false"
					color="#FFF"
					:customize-text="customizeRevenue"
					position="outside"
				/>
			</DxSeries>

			<DxSeries
				value-field="operation_cost"
				name="Operation Cost"
				color="#06ACF7"
			>
				<DxLabel
					:visible="false"
					color="#FFF"
					:customize-text="customizeTextCost"
					position="outside"
				/>
			</DxSeries>

			<DxValueAxis>
				<DxTick :visible="false" />
				<DxLabel :visible="false" />
			</DxValueAxis>
			<DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
			<DxArgumentAxis :tick-interval="1">
				<DxLabel>
					<DxFont size="16" color="#FFFFFF" />
				</DxLabel>
			</DxArgumentAxis>
			<DxLegend
				:margin="{ left: 50 }"
				vertical-alignment="top"
				horizontal-alignment="center"
			>
				<DxFont size="15" color="#FFFFFF" />
			</DxLegend>
		</DxChart>
	</div>
</template>

<script>
import DxChart, {
	DxCommonSeriesSettings,
	DxValueAxis,
	DxLabel,
	DxLegend,
	DxSeries,
	DxTooltip,
	DxSize,
	DxTick,
	DxCommonAxisSettings,
	DxGrid,
	DxFont,
	DxArgumentAxis,
} from "devextreme-vue/chart";

export default {
	components: {
		DxChart,
		DxCommonSeriesSettings,
		DxValueAxis,
		DxLabel,
		DxLegend,
		DxSize,
		DxSeries,
		DxTooltip,
		DxFont,
		DxTick,
		DxCommonAxisSettings,
		DxGrid,
		DxArgumentAxis,
	},
	props: {
		monthlyData: Object,
		dataSource: Array,
	},
	data() {
		return {
			total: 0,
		};
	},
	mounted() {
		this.calculateTotal();
	},
	watch: {
		dataSource: function (newVal, oldVal) {
			// watch it
			this.calculateTotal();
		},
	},
	methods: {
		changeFormat(money) {
			var formatter = new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",
				maximumFractionDigits: 0,
				// These options are needed to round to whole numbers if that's what you want.
				//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
				//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
			});

			return formatter.format(money);
		},
		customizeTooltip({ valueText }) {
			return { text: Math.abs(valueText) };
		},
		customizeLabel({ value }) {
			return `${Math.abs(value)}%`;
		},
		calculateTotal() {
			let data = this.dataSource;
			let total = 0;
			for (var i = 0; i < data.length; i++) {
				total =
					total + data[i].off_peak + data[i].peak + data[i].mid_peak;
			}
			this.total = total;
		},
		customizeTextCost({ argument, value }) {
			return `${value * -1}`;
		},
		customizeRevenue({ argument, value }) {
			return `${value}`;
		},
	},
};
</script>

<style>
#monthlychart {
	width: 100%;
	height: 67%;
}

.dxc-legend text {
	color: white;
	font-size: 16px !important;
	font-family: "Helvetica Neue";
	font-weight: normal;
}
</style>
