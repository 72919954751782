<template>
	<div id="scheduleoperation">
		<div class="title">SCHEDULE OF OPERATION</div>
		<p class="partTitle">Real Time</p>

		<div style="display: flex">
			<DxPieChart
				id="schedulepie"
				:data-source="realTimeData"
				:customize-point="customizePoint"
				:minDiameter="0.8"
			>
				<DxSeries argument-field="region">
					<DxLabel
						:customize-text="customizeText"
						background-color="transparent"
						:visible="true"
						position="outside"
					/>
				</DxSeries>
				<DxLegend
					:visible="false"
					vertical-alignment="top"
					horizontal-alignment="right"
				/>
			</DxPieChart>
			<div id="operationChartLegend">
				<div>
					<div class="dot" style="background: #33bbfa"></div>
					<p>Charging Site Control</p>
				</div>
				<div>
					<div class="dot" style="background: #5fffaf"></div>
					<p>Utility Control</p>
				</div>
				<div>
					<div class="dot" style="background: #c92b56"></div>
					<p>No Applicable</p>
				</div>
				<div>
					<div class="dot" style="background: #beeaff"></div>
					<p>Out of Service</p>
				</div>
			</div>
		</div>
		<div>
			<p class="partTitle">Monthly Analysis</p>
			<DxChart
				:bar-group-width="34"
				id="monthly-analysis"
				:data-source="monthlyAnalysisData"
			>
				<DxCommonSeriesSettings
					argument-field="time"
					type="stackedbar"
				/>
				<DxCommonAxisSettings>
					<DxGrid :visible="false" />
				</DxCommonAxisSettings>
				<DxSeries
					value-field="charging_site_control"
					name="Charging Site Control"
					color="#33BBFA"
				/>
				<DxSeries
					value-field="utility_control"
					color="#5FFFAF"
					name="Utility Control"
				/>
				<DxSeries
					value-field="no_applicable"
					color="#C92B56"
					name="No Applicable"
				/>
				<DxSeries
					value-field="out_of_service"
					color="#BEEAFF"
					name="Out of Service"
				/>
				<DxLegend
					:visible="false"
					vertical-alignment="top"
					horizontal-alignment="center"
					item-text-position="top"
				/>
				<DxValueAxis>
					<DxTick :visible="false" />
					<DxLabel :visible="false" />
				</DxValueAxis>
				<DxArgumentAxis>
					<DxLabel>
						<DxFont size="16" color="#61bfeb" />
					</DxLabel>
				</DxArgumentAxis>
				<DxTooltip :enabled="true" />
			</DxChart>
		</div>
	</div>
</template>
<script>
import DxPieChart, {
	DxLegend,
	DxTooltip,
	DxFormat,
	DxConnector,
	DxExport,
	DxTitle,
	DxAnnotation,
} from "devextreme-vue/pie-chart";

import DxChart, {
	DxCommonSeriesSettings,
	DxCommonAxisSettings,
	DxValueAxis,
	DxLabel,
	DxSeries,
	DxGrid,
	DxTick,
	DxArgumentAxis,
	DxFont,
} from "devextreme-vue/chart";

import { realtime, monthlyAnalysis } from "../DummyData/ScheduleOperation.js";

export default {
	components: {
		DxPieChart,
		DxLegend,
		DxSeries,
		DxAnnotation,
		DxTooltip,
		DxFormat,
		DxLabel,
		DxConnector,
		DxExport,
		DxCommonSeriesSettings,
		DxCommonAxisSettings,
		DxArgumentAxis,
		DxValueAxis,
		DxTitle,
		DxChart,
		DxGrid,
		DxTick,
		DxFont,
	},
	props: {
		realTimeData: Array,
		monthlyAnalysisData: Array,
	},
	data: () => ({
		realtime: [],
		monthlyAnalysis: monthlyAnalysis,
	}),
	mounted() {
		// console.log(this.props);
		// this.realtime = this.props.realtimeData;
	},
	methods: {
		customizePoint(pointInfo) {
			let color = "";
			switch (pointInfo.argument) {
				case "Charging Site Control":
					color = "#33BBFA";
					break;
				case "Utility Control":
					color = "#5FFFAF";
					break;
				case "No Applicable":
					color = "#C92B56";
					break;
				case "Out of Service":
					color = "#BEEAFF";
					break;

				default:
				// code block
			}
			return color != "" ? { color: color } : {};
		},
		customizeTooltip(pointInfo) {
			return {
				text: `${pointInfo.seriesName} years: ${pointInfo.valueText}`,
			};
		},
		customizeText(pointInfo) {
			return `${pointInfo.percentText}`;
		},
	},
};
</script>
<style>
#scheduleoperation {
	margin-top: 10px;
}

#schedulepie {
	flex: 6;
}

#monthly-analysis {
	height: 180px;
	width: 100%;
	padding: 0px 25px;
}
.dxc-labels-group text {
	font-size: 18px !important;
}

#schedulepie .dxc-legend {
}
.dxc-legend text {
	color: white;
	font-size: 16px !important;
	font-family: "Helvetica Neue";
	font-weight: normal;
}

.dxc-axes-group {
	display: none !important;
}

#operationChartLegend {
	display: flex;
	flex: 4;
	flex-direction: column;
	flex-wrap: wrap;
	padding-bottom: 30px;
}

#operationChartLegend div {
	display: flex;
	height: 25px;
	text-align: center;
	line-height: 25px;
	vertical-align: "middle";
}

#operationChartLegend p {
	font-size: 16px;
	color: #fff !important;
	text-align: left;
}

#operationChartLegend .dot {
	margin-top: 5px;
	margin-right: 10px;
	width: 15px;
	height: 15px;
	border-radius: 10px;
}

.partTitle {
	font-size: 16px;
	margin-top: 16px;
	margin-left: 40px;
}
</style>
