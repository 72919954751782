<template>
	<div id="map">
		<div id="search-field">
			<input
				type="text"
				v-model="searchKey"
				@input="onSearching"
				@focus="isSearching = true"
			/>
		</div>
		<ul v-show="isSearching" id="search-result">
			<li v-for="item in searchResult" :key="item.id">
				<div v-on:click="onPickItem(item)">{{ item.Name }}</div>
			</li>
		</ul>
		<div id="site-status-bound">
			<div id="map-site-status">
				<span class="online">{{ totalNormal }}</span>
				<span class="alarm">{{ totalAlarm }}</span>
				<span class="total">{{ totalNormal + totalAlarm }}</span>
			</div>
		</div>

		<div
			v-for="site in status"
			v-bind:style="getStyle(site)"
			:key="site.id"
			class="site-dot"
			:class="{ dot_active: site == currentSite }"
			@click="onPickItem(site)"
		></div>
	</div>
</template>

<script>
import { status } from "../DummyData/SiteStatus";

export default {
	components: {},
	data: () => ({
		status,
		totalNormal: 0,
		totalAlarm: 0,
		isSearching: false,
		searchResult: [],
		searchKey: "",
		currentSite: status[0],
	}),
	mounted() {
		this.loadData();
		this.calculateAlarm();
	},
	methods: {
		onPickItem(item) {
			this.currentSite = item;
			console.log(item.Name);
			this.isSearching = false;
			this.$emit("changeSite", item.Name);
		},
		onSearching() {
			let result = [];
			this.status.map((item) => {
				if (
					item.Name.toString()
						.toUpperCase()
						.includes(this.searchKey.toUpperCase())
				) {
					result.push(item);
				}
			});
			this.searchResult = result;
		},
		calculateAlarm() {
			let totalNormal = 0;
			status.map((site) => {
				if (site.Operation_Status == "Normal") {
					totalNormal++;
				}
			});

			this.totalNormal = totalNormal;
			this.totalAlarm = status.length - totalNormal;
		},

		getStyle(site) {
			let style =
				"left:" +
				site.location.left +
				"; top:" +
				site.location.top +
				";";

			if (site.Operation_Status == "Normal") {
				style = style + " background-color: #5FFFAF";
			} else {
				style = style + " background-color: #FF9F58";
			}
			return style;
		},

		loadData() {
			console.log("load");
		},
	},
};
</script>

<style>
#map {
	width: 100%;
	height: 588px;
	background: url("../assets/images/Image 80.svg") no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
}

#map-site-status {
	width: 82.73px;
	height: 175.88px;
	background: url("../assets/images/site_status_bg.svg") no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
}

#map-site-status span {
	position: absolute;
	left: 17px;
	font-family: Helvetica;
	font-weight: normal;
	font-size: 24px;
	letter-spacing: 0.09em;
}

#map-site-status .online {
	top: 42px;
	color: #5fffaf;
}

#map-site-status .alarm {
	top: 90px;
	color: #ff9f58;
}

#map-site-status .total {
	top: 136px;
	color: #5bcafd;
}

#site-status-bound {
	position: absolute;
	top: 33px;
	right: 30px;
}

#search-field {
	position: absolute;
	left: 31px;
	top: 25px;
}

#search-field input {
	background: url("../assets/images/search_bg.svg") no-repeat;
	background-size: cover;
	background-position: center;
	width: 343px;
	height: 36px;
	padding-left: 85px;
	border: 0;
}

#search-field input:focus {
	border: 0;
}

.site-dot {
	position: absolute;
	width: 14px;
	height: 14px;
	border-radius: 14px;
	cursor: pointer;
}

#search-result {
	position: absolute;
	left: 85px;
	top: 65px;
	width: 280px;
	height: 175px;
}

#search-result li {
	height: 30px;
	list-style-type: none;
	height: 35px;
	border-bottom: 1px solid;
}

.dot_active {
	animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(2, 2);
	}
	100% {
		transform: scale(1, 1);
	}
}
</style>
