export const realtime = [
	{
		region: "Charging Site Control",
		val: 240,
	},
	{
		region: "Utility Control",
		val: 100,
	},
	{
		region: "No Applicable",
		val: 560,
	},
	{
		region: "Out of Service",
		val: 100,
	},
];

export const monthlyAnalysis = [
	{
		index: 1,
		time: "Aug-20",
		charging_site_control: 21,
		utility_control: 13,
		no_applicable: 46,
		out_of_service: 20,
	},
	{
		index: 2,
		time: "Sep-20",
		charging_site_control: 24,
		utility_control: 10,
		no_applicable: 56,
		out_of_service: 10,
	},
	{
		index: 3,
		time: "Oct-20",
		charging_site_control: 21,
		utility_control: 20,
		no_applicable: 62,
		out_of_service: 30,
	},
	{
		index: 4,
		time: "Nov-20",
		charging_site_control: 22,
		utility_control: 15,
		no_applicable: 40,
		out_of_service: 11,
	},
	{
		index: 5,
		time: "Dec-20",
		charging_site_control: 22,
		utility_control: 17,
		no_applicable: 35,
		out_of_service: 15,
	},
	{
		index: 6,
		time: "Jan-20",
		charging_site_control: 28,
		utility_control: 15,
		no_applicable: 50,
		out_of_service: 13,
	},
];
