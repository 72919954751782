<template>
	<div id="dailychartCmp">
		<img id="last7days" src="../assets/images/last7days.svg" />
		<img id="chart_title" src="../assets/images/energy_title.png" />
		<div
			style="
				height: 160px;
				margin-top: 10px;
				padding-left: 19px;
				padding-right: 21px;
			"
		>
			<canvas id="lineChart" width="800"></canvas>
		</div>

		<div
			style="
				height: 170px;
				margin-top: 10px;
				padding-left: 26px;
				padding-right: 26px;
			"
		>
			<canvas id="gradientChartCanvas" width="800"></canvas>
		</div>
	</div>
</template>

<script>
import DxChart, {
	DxArgumentAxis,
	DxCommonSeriesSettings,
	DxLabel,
	DxLegend,
	DxSeries,
	DxValueAxis,
	DxMargin,
	DxFormat,
	DxCommonAxisSettings,
	DxGrid,
	DxTooltip,
	DxFont,
} from "devextreme-vue/chart";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);

export default {
	components: {
		DxTooltip,
		DxArgumentAxis,
		DxGrid,
		DxCommonSeriesSettings,
		DxCommonAxisSettings,
		DxLabel,
		DxLegend,
		DxSeries,
		DxValueAxis,
		DxFormat,
		DxChart,
		DxMargin,
		DxFont,
	},
	props: {
		dailyChargeData: Object,
		deilyChargeDetail: Array,
	},
	data: () => ({
		architectureSources: [{ value: "sum_energy", name: "Sum Energy" }],
		barChart: Object,
		myLineChart: Object,
	}),
	mounted() {
		this.renderBarChart();
		this.renderLineChart();
	},
	watch: {
		deilyChargeDetail: function (newVal, oldVal) {
			this.barChart.destroy();
			this.myLineChart.destroy();
			this.renderBarChart();
			this.renderLineChart();
		},
	},
	methods: {
		renderBarChart() {
			var gradientBarCtx = document
				.getElementById("gradientChartCanvas")
				.getContext("2d");

			var blue_green_gradient = gradientBarCtx.createLinearGradient(
				0,
				0,
				0,
				300
			);
			blue_green_gradient.addColorStop(0.4, "#79d6ff");
			blue_green_gradient.addColorStop(0.48, "#79d6ff");
			blue_green_gradient.addColorStop(1, "#166c93");

			this.barChart = new Chart("gradientChartCanvas", {
				type: "bar",
				plugins: [ChartDataLabels],
				data: {
					// labels: ["1", "2", "3", "4", "5", "6", "7"],
					datasets: [
						{
							data: this.deilyChargeDetail,
							backgroundColor: [blue_green_gradient],
							hoverBackgroundColor: [blue_green_gradient],
							borderRadius: 2,
						},
					],
				},
				options: {
					responsive: true,
					title: {
						enabled: false,
					},
					showTooltips: false,
					maintainAspectRatio: false,
					barPercentage: 0.5,
					tooltips: {
						enabled: false,
					},
					plugins: {
						legend: {
							display: false,
						},
						datalabels: {
							color: "#76CADE",
							formatter: (val) => {
								return val.charge;
							},
							anchor: "end",
							align: "end",
							offset: "10",
						},
					},
					parsing: {
						xAxisKey: "time",
						yAxisKey: "charge",
					},
					scales: {
						x: { ticks: { color: "#FFFFFF", fontSize: 14 } },
						y: {
							ticks: {
								stepSize: 5,
								color: "#E8E8E8",
								fontSize: 14,

								//max: 100
							},
						},
					},
				},
			});
		},
		renderLineChart() {
			var ctx = document.getElementById("lineChart").getContext("2d");

			var gradientFill = ctx.createLinearGradient(200, 0, 200, 200);
			gradientFill.addColorStop(0, "rgba(0, 0, 0, 0)");
			gradientFill.addColorStop(1, "#0e099a");
			gradientFill.addColorStop(0.55, "rgba(14, 9, 154, 0.4)");

			ctx.globalAlpha = 0.7;

			var data = {
				datasets: [
					{
						fill: true,
						backgroundColor: gradientFill,
						lineTension: 0.5,
						borderColor: "#0749FF",
						borderWidth: 1.5,
						data: this.deilyChargeDetail,
					},
				],
			};

			let renderTitle = (tooltipItems) => {
				return "    " + tooltipItems[0].raw.time;
			};

			let renderBeforeLabel = (tooltipItem) => {
				return "Charged Vehicle: " + tooltipItem.raw.charge;
			};
			let renderAfterLabel = (tooltipItem) => {
				return "Consumed Energy: " + tooltipItem.raw.sum_energy + "   ";
			};

			let renderLabel = (tooltipItem) => {
				return "";
			};

			var ttFill = ctx.createLinearGradient(200, 0, 200, 200);
			ttFill.addColorStop(0, "rgba(0, 168, 240, 0.93)");
			ttFill.addColorStop(1, "rgba(0, 84, 120, 0.96)");

			var options = {
				responsive: true,
				maintainAspectRatio: false,
				bezierCurve: true,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						backgroundColor: ttFill,
						callbacks: {
							title: renderTitle,
							label: renderLabel,
							beforeLabel: renderBeforeLabel,
							afterLabel: renderAfterLabel,
						},
					},
				},
				parsing: {
					xAxisKey: "index",
					yAxisKey: "sum_energy",
				},
				scales: {
					x: { display: false },
					y: {
						suggestedMin: 0,
						suggestedMax: 160,
						ticks: {
							stepSize: 20,
							color: "#E8E8E8",
							fontSize: 14,
						},
					},
				},

				tooltipTemplate:
					"<%if (label){%><%=label + ' hod' %>: <%}%><%= value + '°C' %>",
				scaleLabel:
					"<%= Number(value).toFixed(0).replace('.', ',') + '°C'%>",
			};

			this.myLineChart = new Chart("lineChart", {
				type: "line",
				data: data,
				options: options,
			});
		},
	},
};
</script>
<style>
#dailyEnergychart .dxc-elements-axes-group:nth-of-type(2) {
	display: none !important;
}
#dailyEnergychart > svg > g.dxc-axes-group > g.dxc-arg-axis {
	display: none !important;
}
#last7days {
	position: absolute;
	right: 0;
	top: 60px;
}

#chart_title {
	width: 100%;
}

canvas#gradientChartCanvas {
	max-width: 100%;
	height: 200px;
}

#toolTipAreaChart {
	width: 156px;
	height: 64px;
	border-radius: 5px;
	background-color: "red";

	border: 0.5px solid #02f5ff;
}
</style>
